import '../layout/HomepageMobile.scss';
import '../layout/HomepageDesktop.scss';
import { Trans, useTranslation } from "react-i18next"
import shareButton from "../assets/images/share_button.svg"
import shareButtonBlack from "../assets/images/share_button_black.svg"
import category1 from "../assets/images/whiteboard.svg"
import category2 from "../assets/images/laptop-printer.svg"
import category3 from "../assets/images/laptop-tablet.svg"
import category4 from "../assets/images/Ipad.svg"
import afterWealthLogo from "../assets/images/alterwealth_-_logo.svg"
import blgLogo from "../assets/images/LogoWhiteBG.svg"
import bdouinLogo from "../assets/images/Logo_BDouin.svg"
import classroomLogo from "../assets/images/classroom-logo-border-blue-on-white.svg"
import { Player } from "@lottiefiles/react-lottie-player";
import boatWithLogo from "../assets/animations/boat_swinging_with_logo.json"
import boatForeground from "../assets/animations/BOAT_SCENE_01_FOREGROUND.json"
import desktopDownload from "../assets/animations/desktopDownload.json"
import mobileDownload from "../assets/animations/PHONE ANIM.json"
import download from "../assets/animations/download.json"
import contestLogo from "../assets/animations/logo_contest.json"
import awardAnimation from "../assets/animations/award.json"
import previewIpad from "../assets/images/preview_ipad.svg"
import previewMobile from "../assets/images/preview_mobile.svg"
import clouds from "../assets/animations/BIRDS_FLYING_CLOUD_BACK.json"
import { useEffect, useState } from "react";
import calculateRemainingTime from "../utils/remainingTime";
import i18n from "i18next";
import { scrollToElt } from '../utils/scroll';


const url = "https://awladcontest.com";
const title = "Awlad Contest";
const shareDetails = { url, title };

function Homepage() {
    const { t } = useTranslation();
    const [remainingTime, setRemainingTime] = useState({ 'd': 0, 'h': 0, 'm': 0, 's': 0 });

    const handleSharing = async () => {
        if (navigator.share) {
            try {
                await navigator
                    .share(shareDetails)
                    .then(() =>
                        console.log("Hooray! Your content was shared to the world")
                    );
            } catch (error) {
                console.log(`Oops! I couldn't share to the world because: ${error}`);
            }
        } else {
            // fallback code
            console.log(
                "Web share is currently not supported on this browser. Please provide a callback"
            );
            alert("Web share is currently not supported on this browser.");
        }
    };

    useEffect(() => {
        const initialRemainingTime = (calculateRemainingTime(new Date()))
        setRemainingTime(initialRemainingTime)
        const interval = setInterval(() => setRemainingTime(calculateRemainingTime(new Date())), 1000)
        return () => {
            clearInterval(interval)
        };
    }, []);
    const [language, setLanguage] = useState('en');
    useEffect(() => {
        setLanguage(i18n.resolvedLanguage)
        return () => {
        };
    }, [i18n.resolvedLanguage]);

    const winnerBox = (title: string, image: string, name: string, link: string) => {
        return (
            <div className="homepage__awarded-winner-box">
                <h1>{title}</h1>
                <img src={image} alt="Winner Image" />
                <h2>{name}</h2>
                <a href={link} target="_blank" >{t('winner-more')}</a>
            </div>
        )
    }

    return (
        <>
            <div id={'home'} className={'homepage'}>
                <div className={'homepage__description'}>
                    <div className={'logoAwladContest'}>
                        <Player src={contestLogo} className={"contestLogoAnimated"}
                            autoplay={true} loop={true} />
                    </div>
                    <div className={'description'}>
                        <div className={'description1'}>
                            {("fr" != language) ? (<div className={'description1'}>
                                <Trans i18nKey="a-little-spark" />
                            </div>) : (<div className={'description1-french'}>
                                <p className={'hidden mobile:flex'}><Trans i18nKey="a-little-spark-mobile" /></p>
                                <p className={'mobile:hidden flex'}><Trans i18nKey="a-little-spark" /></p>
                            </div>)}
                        </div>
                        <div className={'description2'}>
                            {("fr" != language) ? (<div className={'description2'}>
                                <Trans i18nKey="an-educational" />
                            </div>) : (<div className={'description2-french'}>
                                <p className={'hidden mobile:flex'}><Trans i18nKey="an-educational-mobile" /></p>
                                <p className={'mobile:hidden flex'}><Trans i18nKey="an-educational" /></p>
                            </div>)}
                        </div>
                        <div className={'participate'}>
                            <button onClick={() => scrollToElt("how-to")}><Trans i18nKey="participate" /></button>
                        </div>
                    </div>
                </div>
                <div className={'homepage__awarded'}>
                    <div className={'homepage__awarded-title'}>{t('awardTitle')}<Player src={awardAnimation} loop autoplay className='awardAnimation' /></div>
                    <div className={'homepage__awarded-winner'}>
                        {winnerBox(t("catLess14"), "/images/winner-1.svg", "Leyna", "https://www.instagram.com/p/CmjQjYaoGEc/")}
                        {winnerBox(t("catClass"), "/images/winner-2.svg", "Le centre Idmaj", "https://www.youtube.com/watch?v=J4i1VEFbspI&ab_channel=%C3%89coleIdmaj")}
                        {winnerBox(t("catMore14"), "/images/winner-3.svg", "Ma petite étoile", "https://www.instagram.com/p/CnAxrSgIJFe/")}
                    </div>
                    <div className={'homepage__awarded-winner-special'}>
                        <h1 className={'homepage__awarded-title'}>{t("special-winner-title")}</h1>
                        <div className={'homepage__awarded-winner-special-box'}>
                            <img src="/images/winner-4.svg" alt="Winner Image" />
                            <div>
                                <h2>aria_candramt2</h2>
                                <a href="https://www.instagram.com/reel/Cm_-o-6gy3q/?igshid=ZDFmNTE4Nzc%3D" target="_blank" >{t('winner-more')}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'homepage__soon '}>
                    <div className={'description'}>
                        <div><Trans i18nKey='discover' /></div>
                    </div>
                    <div className={'video relative'}>
                        <div className={'absolute top-0 bottom-0 left-0 right-0 m-auto grid items-center'}>
                            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/nozXWsM5RsA" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                        {/* <div
                            className={'tools-container'}>
                            <div className={'remaining-time'}>
                                <div className={'time-container'}>
                                    <div className={'number'}>{remainingTime.d}</div>
                                    <div className={'unit'}><Trans i18nKey="days" /></div>
                                </div>
                                <div className={'time-container'}>
                                    <div className={'number'}>{remainingTime.h}</div>
                                    <div className={'unit'}><Trans i18nKey="hours" /></div>
                                </div>
                                <div className={'time-container'}>
                                    <div className={'number'}>{remainingTime.m}</div>
                                    <div className={'unit'}><Trans i18nKey="minutes" /></div>
                                </div>
                                <div className={'time-container'}>
                                    <div className={'number'}>{remainingTime.s}</div>
                                    <div className={'unit'}><Trans i18nKey="seconds" /></div>
                                </div>
                            </div>
                            <div className={'z-10 flex justify-center align-center items-center'}>
                                <img className={'play cursor-pointer '} src={play} alt={''} onClick={() => {
                                }} />
                            </div>
                        </div> */}
                    </div>
                    <div
                        onClick={handleSharing}
                        className={'mobile cursor-pointer share flex flex-col justify-center align-center items-center'}>
                        <div className={'grid items-center'}>
                            <img src={shareButton} alt={''} />
                        </div>
                        <div className={'text text-center'}><Trans i18nKey="share" /></div>
                    </div>
                </div>
                <div id={"how-to"} className='anchor'></div>
                <div className={'homepage__how-to'}>
                    <div className={'title'}><Trans i18nKey='how-to-participate' /></div>
                    <div className={'description-title'}>
                        <p><Trans i18nKey='do-you-know' /></p>
                    </div>
                    <div className={'description'}>
                        <div className={'description__1__desktop'}>
                            <Trans i18nKey="if-so-desktop" />
                        </div>
                        <div className={'description__1__mobile'}>
                            <Trans i18nKey="if-so-mobile" />
                        </div>
                        <div className={'description__2'}>
                            <div className={"to-participate-desktop"}>
                                <Trans i18nKey='to-participate-desktop' />
                                <div className={'hashtag-inline'}><p>#AWLADCONTEST</p></div>
                            </div>
                            <div className={"to-participate-mobile"}>
                                <Trans i18nKey='to-participate-mobile' />
                                <div className={'hashtag'}><p>#AWLADCONTEST</p></div>
                            </div>
                        </div>
                    </div>
                    <div className={'categories-separator'}></div>
                    <div className={'categories-container'}>
                        <div className={'categories-title'}>
                            <div className={'categories-title-price'}><Trans i18nKey='number-4' />&nbsp;<Trans i18nKey='4-cat-4-pr' /></div>
                            <br />
                            <p className={'categories-subTitle'}>
                                <Trans i18nKey='to-win' />:
                            </p>
                        </div>
                        <div className={'categories'}>
                            <div className={'category category__1'}>
                                <div className={'title-container'}>
                                    <div className={'title'}><Trans i18nKey={'class'} /></div>
                                </div>
                                <div className={'image'}><img src={category1} alt={''} /></div>
                                <div className={'description'}>
                                    <Trans i18nKey='tact-board' />
                                </div>
                                <div className={'description-desktop'}>
                                    <Trans i18nKey='tact-board' />
                                </div>
                            </div>

                            <div className={'category category__2'}>
                                <div className={'title-container'}>
                                    <div className={'title'}><Trans i18nKey='teacher' /></div>
                                </div>
                                <div className={'image'}><img src={category2} alt={''} /></div>
                                <div className={'description'}>
                                    <Trans i18nKey='laptop-printer' />
                                </div>
                                <div className={'description-desktop'}>
                                    <Trans i18nKey='pro-laptop-printer' />
                                </div>
                            </div>
                            <div className={'category category__3'}>
                                <div className={'title-container '}>
                                    <div className={'title'}><Trans i18nKey='individual' /></div>
                                    <div className={'subTitle'}>
                                        <Trans i18nKey='over-14' />
                                    </div>
                                </div>
                                <div className={'image'}><img src={category3} alt={''} /></div>
                                <div className={'description'}>
                                    <Trans i18nKey='laptop-tablet' />
                                </div>
                                <div className={'description-desktop'}>
                                    <Trans i18nKey='pro-laptop-tablet' />
                                </div>
                            </div>
                            <div className={'category category__4'}>
                                <div className={'title-container'}>
                                    <div className={'title'}><Trans i18nKey='individual' /></div>
                                    <div className={'subTitle'}>
                                        <Trans i18nKey='under-14' />
                                    </div>
                                </div>
                                <div className={'image'}><img src={category4} alt={''} /></div>
                                <div className={'description'}>
                                    <Trans i18nKey='tactile-graphic' />
                                </div>
                                <div className={'description-desktop'}>
                                    <Trans i18nKey='tactile-graphic' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'date-container'}>
                        <div className={'title'}><Trans i18nKey='participation-date' /></div>
                        <div className={'date__desktop'}>
                            <Trans i18nKey='4-dec-4-jan-desktop' />
                        </div>
                        <div className={'date__mobile'}>
                            <Trans i18nKey='4-dec-4-jan-mobile' />
                        </div>
                    </div>
                    <Player autoplay={true} loop={true} className={'clouds'} src={clouds} />
                    <div className={'download-container-desktop mobile:hidden visible '}>
                        <div><Trans i18nKey='presentation-to-understand' />
                        </div>
                        <div className={'slides'}><img src="/images/pdf-preview.svg" /></div>
                        <div className={'panel'}>
                            <div className={'mobile share'}
                                onClick={() => {
                                }}>
                                <img src={shareButtonBlack} alt={''} />
                                <div><Trans i18nKey='share' /></div>
                            </div>
                            <a href={'/pdf/' + t('contest-pdf')} download className={'download cursor-pointer select-none'}>
                                <Player src={download}
                                    className={"download-animation"}
                                    autoplay={true} loop={true} />
                                <div className={'text'}>PDF</div>
                            </a>
                        </div>
                    </div>
                    <div className={'download-container mobile:visible hidden'}>
                        <Trans i18nKey='download-presentation' />
                        <a href={'/pdf/' + t('contest-pdf')} download className={'download cursor-pointer select-none'}>
                            <Player src={download}
                                className={"download-animation"}
                                autoplay={true} loop={true} />
                            <div>PDF</div>
                        </a>
                    </div>
                </div>
                <div className={'homepage__partners'}>
                    <div className={'title'}>
                        <div className={'line'}></div>
                        <div className={'text-partners'}><Trans i18nKey='our-partners' /></div>
                        <div className={'line'}></div>
                    </div>
                    <div className={'flex flex-col justify-center'}>
                        <div className={'partners-container__1'}>
                            <img className={'afterWealthLogo'}
                                src={afterWealthLogo} alt={''} />
                        </div>
                        <div className={'partners-container__2'}>
                            <img className={'bdouinLogo'} src={bdouinLogo} alt={''} />
                            <img className={'afterWealthLogo'} src={afterWealthLogo} alt={''} />
                            <img className={'blgLogo'} src={blgLogo} alt={''} />
                        </div>
                    </div>
                </div>

                <div id={"learn-arabic"} className='anchor'></div>
                <div className={'homepage__learn-more'}>
                    <div className={'description'}>
                        <div className={'text-container'}>
                            <div className={'want-to'}><Trans i18nKey='want-to' /></div>
                            {("fr" != language) ? <div className={'learn'}><Trans i18nKey='learn' /></div>
                                : <div className={'learn-french'}><Trans i18nKey='learn' /></div>}
                            <div className={'practice'}><Trans i18nKey='or-practice-your' /></div>
                            <div className={'arabic'}><Trans i18nKey='arabic-?' /></div>
                        </div>
                        <Player src={boatForeground} className={"boatForeground"}
                            autoplay={true} loop={true} />
                        <Player src={boatWithLogo}
                            className={"boat"}
                            autoplay={true} loop={true} />
                    </div>
                    <div className={'links-desktop visible mobile:hidden'}>
                        <div className={'web-link flex flex-col justify-center items-center'}>
                            <div className={'preview-container'}>
                                <img src={previewIpad} />
                            </div>
                            <div className={'features'}>
                                <div className={'title'}><Trans i18nKey='web-ver' /></div>
                                <div><Trans i18nKey='compatible' /></div>
                                <div>(<Trans i18nKey='french-only' />)</div>
                            </div>
                            <a href="https://hoo-pow.com" target="_blank" className={'link-btn'}><Trans i18nKey='access' /></a>
                            <div className={'free'} style={{ cursor: 'default' }}><Trans i18nKey='free' /></div>
                        </div>
                        <div className={'mobile-link flex flex-col justify-center items-center'}>
                            <div className={'preview-container'}>
                                <img src={previewMobile} />
                            </div>
                            <div className={'features'}>
                                <div className={'title'}><Trans i18nKey='mobile-ver' /></div>
                                <div><Trans i18nKey='Iphone' /></div>
                                <div>(<Trans i18nKey='fr-&-en' />)</div>
                            </div>
                            <a href="https://apple.co/3d41NUp" target="_blank" className={'link-btn'}><Trans i18nKey='download' /></a>
                            {/* <button disabled className={'link-btn'}><Trans i18nKey='soon' /></button> */}
                            <div className={'free'} style={{ cursor: 'default' }}><Trans i18nKey='free' /></div>
                        </div>
                    </div>
                    <div className={'links hidden mobile:visible'}>
                        <div className={'mobile-link flex flex-col justify-center items-center'}>
                            <div className={'flex justify-center items-end'}>
                                <div className={'grid items-center'}>
                                    <Player src={mobileDownload}
                                        className={"mobileDownload"}
                                        autoplay={true} loop={true} />
                                </div>
                                <div className={'iphone'}>
                                    <div className={'title'}>{t('IPHONE')}</div>
                                    <div><Trans i18nKey='french' /></div>
                                    <div><Trans i18nKey='english' /></div>
                                </div>
                            </div>
                            {/* <button disabled className={'link-btn'}><Trans i18nKey='soon' /></button> */}
                            <a href="https://apple.co/3d41NUp" target="_blank" className={'link-btn'}>DOWNLOAD</a>
                            <div className={'free'}><Trans i18nKey='free' /></div>
                        </div>
                        <div className={'web-link flex flex-col justify-center items-center'}>
                            <div className={'flex justify-center items-end'}>
                                <div>
                                    <Player src={desktopDownload}
                                        className={"desktopDownload"}
                                        autoplay={true} loop={true} />
                                </div>
                                <div className={'flex flex-col justify-center items-start'}>
                                    <div className={'title'}>WEB</div>
                                    <div><Trans i18nKey="computer" /></div>
                                    <div><Trans i18nKey="tablet" /></div>
                                    <div><Trans i18nKey="french" /></div>
                                </div>
                            </div>
                            <a href="https://hoo-pow.com" target="_blank" className={'link-btn'}><Trans i18nKey='access' /></a>
                            <div className={'free'}><Trans i18nKey='free' /></div>
                        </div>
                    </div>
                    <div className={'classroom flex flex-col items-center'}>
                        <div className={'classroom-description flex flex-col justify-center'}>
                            <a>
                                <Trans i18nKey='you-school-?' />
                            </a>
                        </div>
                        <div className={'classroom-logo-container flex flex-col justify-center'}>
                            <img className={'classroom-logo'} src={classroomLogo} alt={'AWLAD CLASSROOM'} /></div>
                        <a href="mailto:contact+contest@awladcompany.com" className={'link flex flex-col justify-center cursor-pointer'}>
                            <Trans i18nKey='visit-official' /></a>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Homepage;
