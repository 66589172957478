import ModelViewer from "../elements/ModelViewer";


function ThreeJSTest() {
  return (
    <div>
      <ModelViewer scale={40} modelPath={"/images/the_blender_donut.glb"} position={[0, 0, 0]} />
    </div>
  );
}

export default ThreeJSTest;
