import './Header.scss';
import { useTranslation } from "react-i18next"
import { ReactComponent as AwladContestLogo } from '../assets/awlad/contest-logo_blue.svg';
import { ReactComponent as LeftArrow } from '../assets/awlad/left-arrow.svg';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { scrollToElt } from '../utils/scroll';

function Header(props: { className?: string }) {
  const { className } = props;
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState<string>();
  const [isMenuOpen, setMenuIsOpen] = useState<boolean>();

  const changeLanguageHandler = (event: any) => {
    i18n.changeLanguage(event.target.value.toLowerCase());
    setLanguage(event.target.value.toLowerCase())
  }
  useEffect(() => {
    setLanguage(i18n.resolvedLanguage)
  }, [i18n.resolvedLanguage]);

  return (
    <>
      <header className={className || ''}>
        <nav>
          <div className="burger-wrapper">
            <div className={(isMenuOpen && "open ") + " burger burger-rotate"} onClick={() => setMenuIsOpen(prev => !prev)}>
              <div className="burger-lines"></div>
            </div>
          </div>
          <Link to='/' className='logo'><AwladContestLogo /></Link>
          <div className={(isMenuOpen && "open ") + ' links'}>
            <button id="home_link" className='homepage menu_link selected' onClick={() => scrollToElt("home", () => setMenuIsOpen(false))}>{t('home')}</button>
            <button id="how-to_link" className='donation' onClick={() => scrollToElt("how-to", () => setMenuIsOpen(false))}>{t('how-to-participate')}</button>
            <button id="learn-arabic_link" className='partner' onClick={() => scrollToElt("learn-arabic", () => setMenuIsOpen(false))}>{t('learn-ar')}</button>
          </div>
          <div className='switch-language'>
            <select onChange={changeLanguageHandler} value={language}>
              <option value={'fr'}>FR</option>
              <option value={'en'}>EN</option>
              <option value={'ar'}>AR</option>
              <option value={'id'}>ID</option>
            </select>
            <LeftArrow />
          </div>
        </nav>
      </header>
    </>
  );
}

export default Header;
