import './Footer.scss';
import {Trans, useTranslation} from "react-i18next"
import {ReactComponent as AwladSchoolLogo} from '../assets/awlad/logo.svg';
import {ReactComponent as AwladClassroomLogo} from '../assets/images/CLASSROOM bleu transparent.svg';
import {ReactComponent as AwladContestLogo} from '../assets/images/CONTEST transparent-3.svg';

function Footer(props: { className?: string }) {
    const {t} = useTranslation();

    return (
        <>
            <footer className={props.className || ''}>
                <div className='main-container'>
                    <div className='col col1'>
                        <div className={'logos'}>
                            <a href="https://www.awladschool.org/" target="_blank"><AwladSchoolLogo className={'schoolLogo'}/></a>
                            <AwladClassroomLogo className={'classroomLogo'}/>
                            <AwladContestLogo className={'contestLogo'}/>
                        </div>
                        <p>
                            <Trans i18nKey="footer-desc"/>
                        </p>
                    </div>
                    <div className='col col2'>
                        <div className={'title'}>{t('CONTACT')}</div>
                        {/*TODO: put the right email addresses*/}
                        <div><a href={'mailto:contact+press@awladcompany.com'}>{t("PRESS")}</a></div>
                        <div><a href={`mailto:${t('email-school')}`}>{t("SCHOOL")}</a></div>
                        <div><a href={'mailto:contest@awladcompany.com'}>{t("CONTEST")}</a></div>
                        <div><a href={'mailto:support@awladcompany.com'}>{t("SUPPORT")}</a></div>
                    </div>
                </div>
                <div className='copyright'>{t('footer-copyright')}</div>
            </footer>
        </>
    );
}

export default Footer;
