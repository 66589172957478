
import ScrollToTop from "./elements/ScrollToTop";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import './App.css';
import MainLayout from "./layout/MainLayout";
import Homepage from "./page/Homepage";
import ThreeJSTest from "./page/ThreeJSTest";

function App() {
  return (
    <Router >
      <ScrollToTop />
      <Switch>
        <Route path="/" exact={true} render={HomepageRender} />
        <Route path="/watchpreview" exact={true} render={ThreeJSTest} />
      </Switch>
    </Router>
  );
}

const HomepageRender = (props: any) => (
  <MainLayout key="homepage-page" className="homepage-page">
    <Homepage {...props} />
  </MainLayout>
);

export default App;
