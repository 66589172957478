import './MainLayout.scss';
import Header from './Header';
import Footer from './Footer';
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next"

function MainLayout(props: { children: any, className: string, hideImageBanner?: boolean }) {

  const { i18n } = useTranslation();
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    setLanguage(i18n.resolvedLanguage)
  }, [i18n.resolvedLanguage]);

  return (<>
    <Header className={`header__${props.className || ''} language_${language}`} />
    <div id="main" className={props.className + '_main ' +  'language_' + language} key={props.className}>
      <div className={`ks-mainFrame ${props.className || ''}`}>
        <div id='mainContentScreen' className='ks-content'>
          {props.children}
        </div>
      </div>
      <Footer className={`footer__${props.className || ''}`} />
    </div>
  </>);
}

export default MainLayout;
