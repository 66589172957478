export const scrollToElt = (id: string, callback?: Function) => {
  if (document.getElementById(id)) {
    document.getElementById(id)?.scrollIntoView();

    if (id === 'how-to') {
      document.getElementById('how-to_link')?.classList.add('selected');
      document.getElementById('learn-arabic_link')?.classList.remove('selected');
      document.getElementById('home_link')?.classList.remove('selected');
    } else if (id === 'learn-arabic') {
      document.getElementById('how-to_link')?.classList.remove('selected');
      document.getElementById('learn-arabic_link')?.classList.add('selected');
      document.getElementById('home_link')?.classList.remove('selected');
    } else {
      document.getElementById('how-to_link')?.classList.remove('selected');
      document.getElementById('learn-arabic_link')?.classList.remove('selected');
      document.getElementById('home_link')?.classList.add('selected');
    }
    if (callback) callback();
  }
}