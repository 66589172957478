const calculateRemainingTime = (d: Date) => {

    const finalDate = new Date('4 dec 2022');//TODO use .env
    const remainingMS = finalDate.getTime() - d.getTime()
    if (0 >= remainingMS) {
        return {'d': 0, 'h': 0, 'm': 0, 's': 0}
    }
    const totalRemainingS = remainingMS / 1000
    const secondsPerDay = 24 * 60 * 60;
    const remainingD = totalRemainingS / secondsPerDay
    const secondsPerHour = 60 * 60
    const remainingH = (totalRemainingS % secondsPerDay) / secondsPerHour
    const secondsPerMinute = 60
    const remainingM = ((totalRemainingS % secondsPerDay) % secondsPerHour) / secondsPerMinute
    const remainingS = ((totalRemainingS % secondsPerDay) % secondsPerHour) % secondsPerMinute
    return {
        'd': Math.floor(remainingD),
        'h': Math.floor(remainingH),
        'm': Math.floor(remainingM),
        's': Math.floor(remainingS)
    }
}
export default calculateRemainingTime