import React, { useRef, useState } from "react";
import { useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

const GltfModel = (props: { modelPath: any, scale: number, position: number[] }) => {
  const ref: any = useRef();
  const gltf: any = useLoader(GLTFLoader, props.modelPath);
  const [hovered, hover] = useState(false);

  return (
    <>
      <primitive
        ref={ref}
        object={gltf.scene}
        position={props.position}
        scale={props.scale}
        onPointerOver={(_event: any) => hover(true)}
        onPointerOut={(_event: any) => hover(false)}
      />
    </>
  );
};

export default GltfModel